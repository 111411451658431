<template>
  <section class="wurf part relative">
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <slider
            ref="sliderRef"
            v-slot="{ item, index }"
            class="slider mb-8"
            :items="payload.dogs"
            :slides-per-view="{desktop: 2}"
            :slides-per-group="{desktop: 1}"
            @progress="onProgress"
            @transitionEnd="onProgress"
          >
            <dog-card :ref="el => { if (el) dogCardRefs[index] = el }" :key="item.id" :dog="item" />
          </slider>

          <div class="more">
            <span class="arrow swiper-button-prev" :class="{'opacity-30': state == 'start'}" @click="prev()">
              <Arrow class="arrow-right transform rotate-180" />
            </span>
            <span class="inline-block mx-8"> weitere ansehen </span>
            <span class="arrow swiper-button-next" :class="{'opacity-30': state == 'end'}" @click="next()">
              <Arrow class="arrow-right" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { onBeforeUpdate, ref } from 'vue';
import Arrow from '@/assets/images/arrow-right.svg';
import { useSwiperBounds } from '@/composables/swiper';

export default {
  components: { Arrow },
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const sliderRef = ref(null);
    const dogCardRefs = ref([]);
    const { onProgress: updateBounds, state } = useSwiperBounds();

    onBeforeUpdate(() => {
      dogCardRefs.value = [];
    });

    const prev = () => sliderRef.value?.prev();
    const next = () => sliderRef.value?.next();

    const onProgress = (...args) => {
      updateBounds(...args);
      if (dogCardRefs.value.length > 0) {
        dogCardRefs.value.forEach((r) => r.doTrack());
      }
    };

    return {
      dogCardRefs,
      sliderRef,
      onProgress,
      prev,
      next,
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.wurf {
  position: relative;
  z-index: 10;
  @apply bg-blue;
}

.swiper-container {
  overflow: visible;
  z-index: 100;
}

.dog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: px(90);
  grid-row-gap: px(55);
  margin-bottom: px(55);
}

.arrow {
  @apply cursor-pointer;
  @include responsive('width', 1.5rem, 2.5rem);
  :deep {
    svg path {
      fill: var(--color-very-dark-gray);
    }
  }
}

.slider {
  :deep() {
    .swiper-slide {
    }
  }
}

.more {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-very-dark-gray);
  @include responsive('margin-bottom', 1rem, 2.5rem);
}
</style>
